import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import BubbleNavLink from "../BubbleNavLink";
import "./index.scss";

const Bubble = (props) => {
    const { onBlur, isOpen = true, onClick } = props;
    const scroll = useSelector((store) => store.scroll);

    const bubbleRef = useRef();

    const [indicatorTop, setIndicatorTop] = useState(10);

    const [navItems, setNavItems] = useState([
        {
            title: "Home",
            id: "home",
        },
        {
            title: "Gallery",
            id: "gallery",
        },
        {
            title: "Portfolio",
            id: "portfolio",
        },
        {
            title: "About",
            id: "about",
        },
        {
            title: "Contact",
            id: "contact",
        },
    ]);

    useEffect(() => {
        if (isOpen) {
            document.body.addEventListener("click", onBodyClick, true);
        } else {
            cleanupListener();
        }
        return () => cleanupListener();
    }, [isOpen, scroll]);

    const onBodyClick = (e) => {
        const isDecendant = checkForDecendant(e);
        const isSibling = checkForSibling(e);
        if (!isDecendant && !isSibling) {
            onBlur(false);
        }
    };

    const checkForSibling = (e) => {
        const childElement = e.target;
        const parentElement = bubbleRef.current?.parentElement;
        return parentElement?.contains(childElement);
    };

    const checkForDecendant = (e) => {
        const childElement = e.target;
        return bubbleRef.current?.contains(childElement);
    };

    const cleanupListener = () => {
        document.body.removeEventListener("click", onBodyClick);
    };

    const onMenuItemClicked = (id) => {
        let updatedItems = [...navItems];
        updatedItems = updatedItems.map((item, idx) => {
            return { ...item, isActive: id === idx };
        });
        setNavItems(updatedItems);
        onClick(id);
    };

    const onSectionVisibilityChange = (element) => {
        const offsetTop = element?.current?.offsetTop;
        setIndicatorTop(offsetTop);
    };
    
    const getHeight = () => {
        let element = document.getElementsByClassName("bubble-link-wrapper");
        element = element[0]?.offsetHeight;
        return element;
    };

    return isOpen ? (
                <div className="bubble-wrapper open" ref={bubbleRef}>
                    <div className="arrow-up"></div>
                    <div className="main">
                        {navItems.map((item, idx) => (
                            <BubbleNavLink
                                key={idx}
                                onActive={onSectionVisibilityChange}
                                title={item.title}
                                onClick={onMenuItemClicked.bind(this, item.id)}
                                id={item.id}
                            />
                        ))}
                        <div
                            className="indicator"
                            style={{
                                top: indicatorTop,
                                height: getHeight(),
                            }}
                        ></div>
                    </div>
                </div>
            ) : (<></>);
};

export default Bubble;

import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import "./index.scss";

const BubbleNavLink = (props) => {
    const linkRef = useRef();
    const { title, onClick, onActive = () => {}, id } = props;

    const activeSection = useSelector(store => store.activeSection);

    useEffect(() => {
        if(activeSection === id){
            onActive(linkRef);
        }
    }, [activeSection]);

    return (
        <div ref={linkRef} className={`bubble-link-wrapper`} onClick={onClick} id={`nav-link-${id}`}>
            <span className={`title`}>{title}</span>
        </div>
    );
};

export default BubbleNavLink;

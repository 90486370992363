import "./index.scss";
import React, {useEffect, useRef, useState} from "react";
import Contact from "../sections/Contact";
import Splash from "../sections/Splash";
import About from "../sections/About";
import Gallery from "../sections/Gallery";
import { useDispatch, useSelector } from "react-redux";
import { pxToVw, vwToPixel } from "../../utilites";
import Actions from "../../redux/actions";
import Portfolio from "../sections/Portfolio";

const Home = (props) => {
    const [offsetTop, setOffsetTop] = useState(0);

    useEffect(() => {
        const scrollHandler = () => {
            setOffsetTop(window.scrollY)
        };
        document.addEventListener('scroll', scrollHandler);
        return () => {
            document.removeEventListener('scroll', scrollHandler)
        }
    },[])

    return (
        <>
            <ScreenWrapper isFirst id="home">
                <Splash id="home" />
            </ScreenWrapper>
            <ScreenWrapper id="gallery">
                <Gallery id="galley" />
            </ScreenWrapper>
            <ScreenWrapper id="portfolio">
                <Portfolio id="portfolio" />
            </ScreenWrapper>
            <ScreenWrapper id="about">
                <About id="about" />
            </ScreenWrapper>
            <ScreenWrapper id="contact">
                <Contact id="contact" />
            </ScreenWrapper>
        </>
    );
};

export default Home;

const ScreenWrapper = (props) => {
    const screenRef = useRef();
    const dispatch = useDispatch();
    const { isFirst = false, children, id } = props;
    const [slideIn, setSlideIn] = useState(isFirst);
    const deviceState = useSelector(store => store.deviceState);

    const getMargin = () => {
        if(deviceState === 'mobile') {
            return `${vwToPixel(pxToVw(-10))}px ${vwToPixel(pxToVw(-10))}px`;
        }
        if(deviceState === 'tablet') {
            return `${vwToPixel(pxToVw(-50))}px ${vwToPixel(pxToVw(-50))}px`;
        }
        return `${vwToPixel(pxToVw(-20, 1920))}px ${vwToPixel(pxToVw(-150, 1920))}px`;
    };

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: getMargin(),
            threshold: 0.1
        };
        const observer = new IntersectionObserver((enteries) => {
            const thisEle = enteries[0];
            if(thisEle.isIntersecting) {
                setSlideIn(true);
                dispatch(Actions.setActiveSection(id));
            }
        }, options);
        if(screenRef.current) {
            observer.observe(screenRef.current);
        }
        return () => {
            if(screenRef.current){
                observer.unobserve(screenRef.current);
            }
        }
    }, [screenRef.current]);

    const slideClassName = slideIn ? 'animated':'';
    return (
        <div 
            ref={screenRef} 
            id={`screen-${id}`}
            className={`screen ${isFirst ? 'first':''} ${slideClassName}`}
        >
            {children}
        </div>
    )
}


import React, { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper";
import "./index.scss";
import ArrowUp from '../../../assets/icons/arrow-up.svg'

const IMG_DATA = [
    'https://scontent.ftlv20-1.fna.fbcdn.net/v/t39.30808-6/312621141_437298935185810_4569011326631236184_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=730e14&_nc_ohc=BKdKQ1oDPJsAX9ujs55&_nc_ht=scontent.ftlv20-1.fna&oh=00_AfC3mB7HcPFm-35oO0bFzlm2cHREBh9BHg4_cdADSP5Jsg&oe=639D4776',
    'https://scontent.ftlv20-1.fna.fbcdn.net/v/t39.30808-6/273694374_892351401442185_1860719439722320623_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=730e14&_nc_ohc=_qJQ2srQY74AX8Ga0tJ&tn=8YYloT6nCKWJl0C9&_nc_ht=scontent.ftlv20-1.fna&oh=00_AfDgqH7R3CzraEACj44UvgRL3aY5rTY7_nQwMTeEQlZqNQ&oe=639D05F4',
    'https://scontent.ftlv20-1.fna.fbcdn.net/v/t39.30808-6/245327552_826220654721927_4993157310623504808_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=730e14&_nc_ohc=CZCzPQuFOYEAX80Pdt9&_nc_ht=scontent.ftlv20-1.fna&oh=00_AfCHctknV-nvviItFIcfQSFFD09CMJ8aVxt98nroULn32A&oe=639DBB65',
    'https://scontent.ftlv20-1.fna.fbcdn.net/v/t1.6435-9/65769901_345575376119793_5463172428844761088_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=9267fe&_nc_ohc=hOnSjMj63loAX-gCQqu&_nc_ht=scontent.ftlv20-1.fna&oh=00_AfAtzMDfVHYKgR44NTjYaXD4KX4-rVhgvxKeqTU8xVlr7g&oe=63C039A7',
]

const Gallery = (props) => {
    const swiperRef = useRef();
    const [activeIndex, setActiveIndex] = useState(0);

    const resume = () => {
        swiperRef?.current?.autoplay?.run?.();
    };

    const pause = () => {
        swiperRef?.current?.autoplay?.pause?.();
    };

    const previous = () => {
      swiperRef?.current?.slidePrev?.();
    };

    const next = () => {
        swiperRef?.current?.slideNext?.();
    };

    const swiperOptions = {
        ref: swiperRef,
        modules: [Autoplay],
        loop: true,
        spaceBetween: 20,
        slidesPerView: 4,
        direction: 'vertical',
        className: 'gallery-carousel',
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        },
        onSwiper: swiper => swiperRef.current = swiper,
        // onSlideChange: e => setActiveIndex(e.realIndex)
    }

    return (
        <div className="gallery-wrapper" id='gallery'>
            <div className="gallery-main-section">
                <div className="gallery-title-wrapper">
                   <div className="gallery-texts-wrapper">
                    <div className="gallery-title">
                            Lorem ipsum
                        </div>
                        <div className="gallery-subline">
                            December 2022
                        </div>
                   </div>
                </div>
                <div className="selected-slide-picture-wrapper">
                    <img src={IMG_DATA[activeIndex]} alt="picsum" className="selected-slide-picture"/>
                </div>
                <div 
                    className="gallery-carousel-wrapper"
                    onMouseEnter={pause}
                    onMouseLeave={resume}
                >
                    <button className="arrow-wrapper" onClick={previous}>
                        <img src={ArrowUp} alt='arrow up' />
                    </button>
                    <Swiper {...swiperOptions}>
                        {IMG_DATA.map((img, idx) => (
                            <SwiperSlide 
                                onClick={() => setActiveIndex(idx)}
                                key={`gallery-carousel-image-${idx}`}
                                className={`gallery-carousel-slide ${idx === activeIndex ? 'active':''}`}
                            >
                                <img src={img} alt="picsum" className="gallery-slide-img"/>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <button className="arrow-wrapper flip" onClick={next}>
                        <img src={ArrowUp} alt='arrow down' />
                    </button>
                </div>
            </div>
            
            <NavLink to={"/"} className='see-more-link' >Click here to see more</NavLink>
        </div>
    );
};

export default Gallery;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/Button";
import FloatInput from "../../../components/FloatInput";
import FloatTextArea from "../../../components/FloatTextArea";

import Actions from "../../../redux/actions";
import { BiPaperPlane } from "react-icons/bi";
import { MdAlternateEmail, MdPhone, MdLocationPin } from "react-icons/md";

import ContactImg from "../../../assets/images/contact-img.jpg";

import "./index.scss";
import ContactDetails from "../../../components/ContactDetails";
import { Validate } from "../../../utilites";
import { postContact } from "../../../lib/API/db";

const getFormInitialState = () => {
    return {
        email: {
            isValid: false,
            errMsg: "",
            rules: ["email", "required"],
        },
        msg: {
            isValid: false,
            errMsg: "",
            rules: ["required"],
        },
    };
};

const Contact = (props) => {
    const { id } = props;

    const [disabled, setDisabled] = useState(false);

    const [firstTry, setFirstTry] = useState(true);
    const [formState, setFormState] = useState(getFormInitialState());

    const form = useSelector((store) => store.contactData);
    const dispatch = useDispatch();

    const handleSubmit = async (e) => {
        e.preventDefault();
        let isFormValid = true;
        let updatedState = { ...formState };
        for (let key of Object.keys(formState)) {
            let validationObj = Validate(form[key], formState[key].rules);
            updatedState[key].isValid = validationObj.valid;
            updatedState[key].errMsg = validationObj.msg;
            if (!validationObj.valid) {
                isFormValid = false;
            }
        }
        setFormState(updatedState);
        setFirstTry(false);
        if (!isFormValid) {
            return;
        }
        setDisabled(true);
        setFormState(getFormInitialState());
        setFirstTry(true);
        const res = await postContact(form.full_name, form.email, form.msg);
        if (res) {
            dispatch(
                Actions.addNotification({
                    title: "We've got it",
                    text: "Contact form has been submitted successfully!",
                    duration: 3000,
                    status: "success",
                })
            );
            setDisabled(false);
            dispatch(
                Actions.addField({
                    full_name: "",
                    email: "",
                    msg: "",
                })
            );
        }
    };

    const onFieldChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let updatedState = { ...formState };
        let validationObj = Validate(value, updatedState[name].rules);
        updatedState[name].isValid = validationObj.valid;
        updatedState[name].errMsg = validationObj.msg;
        setFormState(updatedState);
        dispatch(Actions.addField({ [name]: value }));
    };

    const showError = (field) => {
        return !firstTry && !formState[field].isValid;
    };

    return (
        <div className="contact-wrapper" id={id}>
            <h1 className="contact-title">Contact Me</h1>
            <form onSubmit={handleSubmit} className={'contact-form-wrapper'}>
                <FloatInput
                    label="Your Email Address"
                    type="email"
                    name="email"
                    autoComplete={"off"}
                    value={form.email}
                    isValid={formState.email.isValid}
                    errMsg={formState.email.errMsg}
                    onChange={onFieldChange}
                    showError={showError("email")}
                    className="field"
                    disabled={disabled}
                    required
                />
                <FloatTextArea
                    label="Your message"
                    name="msg"
                    value={form.msg}
                    isValid={formState.msg.isValid}
                    errMsg={formState.msg.errMsg}
                    onChange={onFieldChange}
                    showError={showError("msg")}
                    className="field"
                    disabled={disabled}
                    required
                />
                <Button
                    onClick={handleSubmit}
                    text="Send"
                    className={'submit-button'}
                    disabled={disabled}
                />
            </form>
        </div>
    );
};

export default Contact;

import React, { useEffect, useState, useRef } from 'react';
import './index.scss';


const Images = [
    'https://scontent.ftlv20-1.fna.fbcdn.net/v/t39.30808-6/312621141_437298935185810_4569011326631236184_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=730e14&_nc_ohc=BKdKQ1oDPJsAX9ujs55&_nc_ht=scontent.ftlv20-1.fna&oh=00_AfC3mB7HcPFm-35oO0bFzlm2cHREBh9BHg4_cdADSP5Jsg&oe=639D4776',
    'https://scontent.ftlv20-1.fna.fbcdn.net/v/t39.30808-6/273694374_892351401442185_1860719439722320623_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=730e14&_nc_ohc=_qJQ2srQY74AX8Ga0tJ&tn=8YYloT6nCKWJl0C9&_nc_ht=scontent.ftlv20-1.fna&oh=00_AfDgqH7R3CzraEACj44UvgRL3aY5rTY7_nQwMTeEQlZqNQ&oe=639D05F4',
    'https://scontent.ftlv20-1.fna.fbcdn.net/v/t39.30808-6/245327552_826220654721927_4993157310623504808_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=730e14&_nc_ohc=CZCzPQuFOYEAX80Pdt9&_nc_ht=scontent.ftlv20-1.fna&oh=00_AfCHctknV-nvviItFIcfQSFFD09CMJ8aVxt98nroULn32A&oe=639DBB65',
    'https://scontent.ftlv20-1.fna.fbcdn.net/v/t1.6435-9/65769901_345575376119793_5463172428844761088_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=9267fe&_nc_ohc=hOnSjMj63loAX-gCQqu&_nc_ht=scontent.ftlv20-1.fna&oh=00_AfAtzMDfVHYKgR44NTjYaXD4KX4-rVhgvxKeqTU8xVlr7g&oe=63C039A7',
    'https://scontent.ftlv20-1.fna.fbcdn.net/v/t39.30808-6/312621141_437298935185810_4569011326631236184_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=730e14&_nc_ohc=BKdKQ1oDPJsAX9ujs55&_nc_ht=scontent.ftlv20-1.fna&oh=00_AfC3mB7HcPFm-35oO0bFzlm2cHREBh9BHg4_cdADSP5Jsg&oe=639D4776',
    'https://scontent.ftlv20-1.fna.fbcdn.net/v/t39.30808-6/273694374_892351401442185_1860719439722320623_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=730e14&_nc_ohc=_qJQ2srQY74AX8Ga0tJ&tn=8YYloT6nCKWJl0C9&_nc_ht=scontent.ftlv20-1.fna&oh=00_AfDgqH7R3CzraEACj44UvgRL3aY5rTY7_nQwMTeEQlZqNQ&oe=639D05F4',
    'https://scontent.ftlv20-1.fna.fbcdn.net/v/t39.30808-6/245327552_826220654721927_4993157310623504808_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=730e14&_nc_ohc=CZCzPQuFOYEAX80Pdt9&_nc_ht=scontent.ftlv20-1.fna&oh=00_AfCHctknV-nvviItFIcfQSFFD09CMJ8aVxt98nroULn32A&oe=639DBB65',
    'https://scontent.ftlv20-1.fna.fbcdn.net/v/t1.6435-9/65769901_345575376119793_5463172428844761088_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=9267fe&_nc_ohc=hOnSjMj63loAX-gCQqu&_nc_ht=scontent.ftlv20-1.fna&oh=00_AfAtzMDfVHYKgR44NTjYaXD4KX4-rVhgvxKeqTU8xVlr7g&oe=63C039A7',
    'https://scontent.ftlv20-1.fna.fbcdn.net/v/t39.30808-6/312621141_437298935185810_4569011326631236184_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=730e14&_nc_ohc=BKdKQ1oDPJsAX9ujs55&_nc_ht=scontent.ftlv20-1.fna&oh=00_AfC3mB7HcPFm-35oO0bFzlm2cHREBh9BHg4_cdADSP5Jsg&oe=639D4776',
    'https://scontent.ftlv20-1.fna.fbcdn.net/v/t39.30808-6/273694374_892351401442185_1860719439722320623_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=730e14&_nc_ohc=_qJQ2srQY74AX8Ga0tJ&tn=8YYloT6nCKWJl0C9&_nc_ht=scontent.ftlv20-1.fna&oh=00_AfDgqH7R3CzraEACj44UvgRL3aY5rTY7_nQwMTeEQlZqNQ&oe=639D05F4',
];


const Portfolio = (props) => {
    const { id } = props;
    return (
        <div className='portfolio-wrapper' id={id}>
            <div className='portfolio-title'>
                Portfolio
            </div>
            <div className='portfolio-images-wrapper'>
                {Images.map((img, idx) => (
                    <div key={`portfolio-section-image-${idx}`} className='portfolio-image-wrapper'>
                        <img src={img} alt={"portfolio example"} className='portfolio-image' />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Portfolio;
import React from "react";
import "./index.scss";
import { IoIosArrowDown } from "react-icons/io";
import BG from '../../../assets/bg.jpg';
import { useSelector } from "react-redux";

const Splash = (props) => {
    const { id } = props;
    const scroll = useSelector((store) => store.scroll);

    const scrollingDown = scroll?.direction === 'down';
    return (
        <div className="splash-wrapper" id={id}>
            <div className="splash-background-wrapper">
                <img src={BG} alt='background' className="splash-background"/>
            </div>
            <div className="text"> 
            <div className="headline">Welcome</div>
            <div className='subline'>to my portfolio!</div>
            </div>
            {scrollingDown && (
                <div className="icon">
                    <IoIosArrowDown width={'100%'} height={'100%'}/>
                </div>
            )}
        </div>
    );
};

export default Splash;
